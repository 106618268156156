export default [
  {
    text: 'screensaver.form.name',
    value: 'name',
  },
  {
    text: 'screensaver.form.customerGroup',
    value: 'customerGroup',
  },
  {
    text: 'screensaver.form.linkedCustomerGroup',
    value: 'linkedCustomerGroup',
  },
  {
    text: 'screensaver.table.steps',
    value: 'steps',
    type: 'slot',
  },
  {
    text: 'screensaver.form.venues',
    value: 'venues',
    type: 'slot',
  },
];
