
import { Component, Watch } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { mixins } from 'vue-class-component';
import CustomerGroup from '@/mixins/CustomerGroup';
import { namespace } from 'vuex-class';
import Screensaver from '@/interfaces/models/Screensaver';
import ScreensaverTable from '@/components/screensaver/ScreensaverTable.vue';
import { Venue } from '@/interfaces/models/Venue';
import VItemSelect from '@/components/shared/table/VItemSelect.vue';
import VFormBuilder from '@/components/shared/form/VFormBuilder.vue';
import Filter from '@/interfaces/api/Filter';

const screensaver = namespace('screensaver');
const app = namespace('app');

@Component({
  components: { VFormBuilder, VItemSelect, ScreensaverTable, VWrapper },
})
export default class ScreensaverView extends mixins(CustomerGroup) {
  @screensaver.State('items') public screensavers!: Screensaver[];
  @screensaver.State('filter') public filter!: Filter;
  @screensaver.Action('fetch') public getScreensavers!: any;
  @screensaver.Action('delete') public deleteScreensaver!: any;
  @screensaver.Action('setFilter') public setFilter!: any;
  @screensaver.Action('setInitialItems') public setInitialItems!: any;

  @app.State('venues') public venues!: Venue[];
  @app.Action('fetchVenues') public getVenues!: any;

  public customerGroup: string[] = [];
  public selectedVenues: string[] = [];

  public async created() {
    this.setFilter({});
    this.$startLoading('venues');
    await this.getVenues();
    this.$stopLoading('venues');
  }

  public filterCustomerGroupsFromDefault() {
    return this.scopedCustomerGroups.filter((cGroup) => cGroup.value !== '');
  }

  public async fetchScreensavers() {
    this.$startLoading('screensaver');
    await this.getScreensavers();
    this.$stopLoading('screensaver');
  }

  @Watch('selectedVenues')
  public async onVenuesChange() {
    this.setFilter({ ...this.filter, venues: this.selectedVenues });
    await this.fetchScreensavers();
  }

  @Watch('customerGroup')
  public async onCustomerGroupChange() {
    this.setFilter({ ...this.filter, customerGroups: this.customerGroup });
    await this.fetchScreensavers();
  }
}
