
import { Component, Prop, Vue } from 'vue-property-decorator';
import VTable from '@/components/shared/table/VTable.vue';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import headers from './headers';
import Screensaver from '@/interfaces/models/Screensaver';
import { Venue } from '@/interfaces/models/Venue';

@Component({
  components: { VTable },
})
export default class ScreensaverTable extends Vue {
  @Prop({ type: Array, required: true }) public items!: Screensaver[];
  @Prop({ type: Array, required: true }) public venues!: Venue[];

  public headers: ColumnHeader[] = headers;

  public venueNames(venues: string[]) {
    if (!venues || venues.length === 0) {
      return '-';
    }
    const names: Array<string | undefined> = this.venues.map((v: Venue) => {
      if (venues && venues!.indexOf(v._id) !== -1) {
        return v.name;
      }
    });

    return names.filter((n: string | undefined) => n !== undefined).join(', ');
  }
}
